import { Location } from '@wix/ambassador-availability-calendar/types';

export type FormSelectedSlot = {
  timezone: string;
  startDate: string;
  endDate: string;
  nestedSlots: FormNestedSlot[];
  location?: Location;
  openSpots?: number;
  totalSpots?: number;
  v2Availability?: boolean;
};

export type FormNestedSlot = {
  lineItemId: string;
  isAdditionalSlot?: boolean;
  availabilityType: AvailabilityType;
  timezone: string;
  serviceId: string;
  startDate: string;
  endDate: string;
  scheduleId?: string;
  sessionId?: string;
  location?: Location;
  resource?: {
    id?: string;
    name?: string;
    scheduleId?: string;
  };
};

export enum AvailabilityType {
  STANDARD = 'STANDARD',
  GOLDEN = 'GOLDEN',
}
