import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { resolveURLData } from './URLAdapter/URLDataResolver';
import { FormPageData, PageAPIData } from '../../types/types';
import { FormApi } from '../FormApi';
import { mapSlotAvailabilityToFormSelectedSlot } from '../../utils/mappers/form-selected-slot.mapper';
import { ExperimentsConsts } from '../../consts/experiments';

export const getPageAPIData = async ({
  flowAPI,
  formApi,
}: {
  flowAPI: ControllerFlowAPI;
  formApi: FormApi;
}): Promise<FormPageData | undefined> => {
  try {
    const isNavigateWithCloudEnabled = flowAPI.experiments.enabled(
      ExperimentsConsts.NavigateWithCloud,
    );
    let pageData: PageAPIData | undefined;

    if (isNavigateWithCloudEnabled) {
      try {
        pageData = (await formApi.getPageData())?.pageData;
      } catch (e) {
        console.error(e);
      }
    }

    if (!pageData) {
      // FOR DEPRECATED NAVIGATIONS
      // this is to support old navigation via url or sessions storage
      // the best practice is to set the page data via api and navigate just with the returned id
      pageData = await resolveURLData({ flowAPI });

      if (pageData && isNavigateWithCloudEnabled) {
        await formApi.setPageData({
          pageData,
          flowAPI,
        });
      }
    }

    if (pageData) {
      return mapSlotAvailabilityToFormSelectedSlot(
        pageData,
        flowAPI.experiments,
      );
    }

    throw new Error('ERROR: failed to infer URL data service type');
  } catch (error) {
    console.error('ERROR: failed to resolve page API data', error);
    return undefined;
  }
};
